<template>
  <b-card>

    <h2>Tambah Log Kendaraan</h2>

    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Untuk menambah log kendaraan, pengguna dapat mengakses dan mengisi field yang ada dalam menu tambah log kendaraan seperti berikut.</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 305.33px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image9.png')"
          style="width: 601.70px; height: 305.33px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c3">
      <span class="c2">Jika pengemudi &nbsp;kendaraan lebih dari 1 maka pengguna dapat menambah detail kendaraan seperti berikut.</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 208.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image5.png')"
          style="width: 601.70px; height: 208.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">setelah semua data terisi maka pengguna dapat memilih tombol simpan dan data log kendaraan akan muncul pada menu daftar log kendaraan seperti berikut.</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 248.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image18.png')"
          style="width: 601.70px; height: 248.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <ul class="c8 lst-kix_gqygpr6r2y3-0 start">
      <li class="c3 c6 c4 li-bullet-0">
        <span class="c1">Detail atau edit data log kendaraan</span>
      </li>
    </ul>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">untuk melihat detail pengguna dapat memilih nomor log kendaraan yang tertera dalam tabel log kendaraan, sedangkan untuk edit data terdapat pada kolom asi seperti pada ilustrasi berikut. untuk edit data harus menggunakan akun staff SE.</span>
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 148.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image23.png')"
          style="width: 601.70px; height: 148.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">selanjutnya maka akan muncul detail log kendaraan seperti pada ilustrasi berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 306.67px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image4.png')"
          style="width: 601.70px; height: 306.67px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">Jika pengguna adalah staff SE, mala akan muncul tombol edit log kendaraan seperti berikut.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 601.70px; height: 128.00px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-2/image8.png')"
          style="width: 601.70px; height: 128.00px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c3">
      <span class="c2">setelah mengganti isi dokumen maka pengguna dapat memilih tombol simpan untuk menyelesaikan edit dokumen log kendaraan.</span>
    </p>
    <p class="c0">
      <span class="c2" />
    </p>
    <p class="c0">
      <span class="c2" />
    </p>

  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 0;
  li {
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
